import axios from './BaseService';

const LOGS_URL = `${process.env.REACT_APP_API_URL}/logs/`;

export async function getLogs(file, token) {
    const headers = { 'authorization': token };
    const response = await axios.get(LOGS_URL + file, { headers });
    return response.data;
}

export async function getLogList(userId, page, token) {
    // const headers = { 'authorization': token };
    // const response = await axios.get(`${LOGS_URL}?page=${page}&userId=${userId ? userId : ''}`, { headers });
    // return response.data;    

    return new Promise((resolve, reject) => {
        resolve({
            rows: ['M1.log', 'M2.log'],
            count: 2
        });
    })
}
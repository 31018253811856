import React from 'react';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';


function Limits() {

  return (
    <>
      <Menu />
      <main className="content">
      
         <h1>Limits</h1>
        <Footer />
      </main>
    </>
  );
}

export default Limits;
import axios from './BaseService';

const LIMITS_URL = `${process.env.REACT_APP_API_URL}/limits/`;

export async function getLimits(page, token) {
    //const LimitsUrl = `${LIMITS_URL}?page=${page}`;

    // const headers = { 'authorization': token };
    //  const response = await axios.get(LimitsUrl, { headers });
    //  return response.data;//{count, rows}

    let limits = [{
        id: 1,
        name: 'Gold',
        isActive: true
    }, {
        id: 2,
        name: 'Silver',
        isActive: false
    }]

    return new Promise((resolve, reject) => {
        return resolve({
            rows: limits,
            count: limits.length
        })
    })
}

export async function getAllLimits(token) {
     let limits = [{
        id: 1,
        name: 'Gold',
        isActive: true
    }, {
        id: 2,
        name: 'Silver',
        isActive: false
    }]

    return new Promise((resolve, reject) => {
        return resolve(limits)
    })
}

export async function getActiveLimits(token) {
    //const LimitsUrl = `${LIMITS_URL}?page=${page}`;

    // const headers = { 'authorization': token };
    //  const response = await axios.get(LimitsUrl, { headers });
    //  return response.data;//{count, rows}

    let limits = [{
        id: 1,
        name: 'Gold',
        isActive: true
    }]

    return new Promise((resolve, reject) => {
        return resolve(limits)
    })
}

export async function saveLimit(id, newLimits, token) {
    // const headers = { 'authorization': token };
    // let response;
    // if (id)
    //     response = await axios.patch(`${LIMITS_URL}${id}`, newLimits, { headers });
    // else
    //     response = await axios.post(LIMITS_URL, newLimits, { headers });
    // return response.data;
    return new Promise((resolve, reject) => {
        return resolve({ })
    })
}

export async function deleteLimit(id, token) {
    // const headers = { 'authorization': token };
    // const response = await axios.delete(`${LIMITS_URL}${id}`, { headers });
    // return response.data;
    return new Promise((resolve, reject) => {
        return resolve({ })
    })
}

export async function startLimit(id, token) {
    // const headers = { 'authorization': token };
    // const response = await axios.post(`${LIMITS_URL}${id}/start`, {}, { headers });
    // return response.data;
    return new Promise((resolve, reject) => {
        return resolve({ })
    })
}

export async function stopLimit(id, token) {
    // const headers = { 'authorization': token };
    // const response = await axios.post(`${LIMITS_URL}${id}/stop`, {}, { headers });
    // return response.data;
    return new Promise((resolve, reject) => {
        return resolve({ })
    })
}